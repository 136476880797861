import { clientConfig } from "@root/_store"

export class PusherService {
  _pusher: any = undefined;
  socketId: string;
  beamsClient: any = undefined;
  beamsTokenProvider: any = undefined;
  initiated: boolean = false;
  constructor(userId = undefined) {  
    if (window.indexedDB && userId) {
      // Skip during build
      this.initBeams(userId);
    }
  }

  public async registerInterests(beamsDeviceId = null) {
    if(!beamsDeviceId) beamsDeviceId = await this.beamsClient.getDeviceId();
    if (!beamsDeviceId) {
      console.error("Error, not registered with Beams (No DeviceId)");
      return;
    }
    console.log("Successfully registered with Beams. Device ID:", beamsDeviceId);
    console.log("Successfully added beams hello interest:", await this.beamsClient.addDeviceInterest("hello"));
    console.log("Successfully added beams debug-hello interest:", await this.beamsClient.addDeviceInterest("debug-hello"));
    console.log("Successfully added beams events interest:", await this.beamsClient.addDeviceInterest("events"));
    console.log("Successfully added beams posts interest:", await this.beamsClient.addDeviceInterest("posts"));
  }

   public async registerUser(register, userId) {
    const currentUserId = await this.beamsClient.getUserId()
    const beamsDeviceId = await this.beamsClient.getDeviceId();
    console.log("Current beams user id:", currentUserId);
    console.log("Current beams device id:", beamsDeviceId);
    if (register) {
      console.log("Registering beams user", userId);
      this.beamsClient
        .start()
        .then(() => currentUserId ? console.log("User already registered") : this.beamsClient.setUserId(userId, this.beamsTokenProvider))
        .then(() => this.registerInterests())
        .catch(console.error);
    } else {
      this.beamsClient
        .getUserId()
        .then((id) => {
          console.log("Beams userId check", id !== null && id === userId ? true : id + ", " + userId);
          // Check if the Beams user matches the user that is currently logged in
          if (id && userId !== id) {
            // Unregister for notifications
            console.log("Unregister for beams notifications, userIds do not match", id, userId);
            return this.beamsClient.stop();
          } else if (id !== null && userId === id) {
            this.registerInterests();
          } else if (id === null && userId) {
            console.log("Beamsclient not yet registered with user, trying now");
            this.registerUser(true, userId);
          }
        })
        .catch(console.error);
    }
  }

  public async initBeams(userId, serviceWorkerRegistration = null, login = false) {
    if (userId && this.beamsClient && this.beamsTokenProvider) {
      console.log("Start beams client");
      await this.registerUser(login, userId);
    } else if (userId) {
      console.error("Beamsclient not started, trying now", this.beamsClient);
      import("./beams").then(async ({ beamsClient, beamsTokenProvider }) => {
        this.beamsClient = await beamsClient(serviceWorkerRegistration);
        this.beamsTokenProvider = await beamsTokenProvider;
        console.log("Start beams client after import", this.beamsClient);
        await this.registerUser(login, userId);
      });
    } else {
      console.log("Not starting Beams, no user logged in");
    }
  }

  async _waitforcreation() {
    while (!this._pusher)
      // define the condition as you like
      await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  async getInstance(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this._pusher) resolve(this._pusher);
      if (this.initiated) {
        // In case of simultaneous initialization
        await this._waitforcreation();
        resolve(this._pusher);
      } else if (window.indexedDB && window.Pusher) {
        this.initiated = true;
        // Skip during build
        Pusher.logToConsole = false;

        console.log("Start Pusher client")

        let token;
        if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).token)
          token = JSON.parse(localStorage.getItem("user")).token;

        this._pusher = new Pusher(PUSHER_KEY, {
          cluster: "eu",
          userAuthentication: {
             endpoint: clientConfig.get("PUSHER_URI") + "/user-auth",
             headers: {
              authorization: "Bearer " + token,
             }
          },
          channelAuthorization: { endpoint: clientConfig.get("PUSHER_URI") + "/auth"}
        });
        this._pusher.connection.bind("connected", () => {
          this.socketId = this._pusher.connection.socket_id;
          resolve(this._pusher);
          console.log("Successfully registered with Pusher. Session ID:", this._pusher.sessionID, "Socket ID:", this.socketId);
        });
        this._pusher.signin();
        var channel = this._pusher.subscribe("my-channel");
        channel.bind("my-event", function (data) {
          alert(JSON.stringify(data));
        });
        var presence = this._pusher.subscribe("presence-online");
        presence.bind("pusher:subscription_succeeded", (members) => {
          console.log("Online pusher members", members.count);
        
          members.each((member) => {
            console.log("pusher online", member.id, member.info);
          });
        });
        presence.bind("pusher:member_removed", (member) => {
          console.log("pusher offline", member.id, member.info);
        });
      }
    });
  }
}
