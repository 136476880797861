import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache, from } from "@apollo/client/core";
import { setClient } from "svelte-apollo";
import { onError } from "@apollo/client/link/error";
import { clientConfig, session } from "@root/_store";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (!graphQLErrors && networkError) console.log(`[Network error]: ${networkError}`);
});

export function getGraphqlLink() {
  const authLink = setContext((_, { headers, ...context }) => {    
    return {
      headers: {
        ...headers,
        ...session.authorizationHeader,
      },
      ...context,
    };
  });

  const httpLink = createHttpLink({ uri: clientConfig.get("GRAPHQL_URI") });

  const link = ApolloLink.from([authLink, httpLink]);
  return link;
}

export function updateGraphqlClient() {
  const link = getGraphqlLink();
  setClient(
    new ApolloClient({
      link: from([errorLink, link]),
      cache: new InMemoryCache({
        //addTypename: false // TODO: fix resolveReferenceObjectToId in gql utils first
      }),
    })
  );
}

export function graphqlError(origin, error) {
  console.error(`Error occured in ${origin}:`, error.message);
}