import "./tailwind.css";
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
window["bootstrap"] = bootstrap;
import App from "./App.svelte";

['clear', 'log', 'info', 'dir', 'warn', 'error', 'table'].forEach((level) => {
    const original = console[level];
    console[level] = (...args) => {

        // Convert all arguments to a JSON string
        const stringifiedArgs = args.map(arg => {
            if (typeof arg === 'object') {
                try {
                    return JSON.stringify(arg, null, 2);
                } catch (err) {
                    return '[Unclonable Object]';
                }
            } else {
                return arg;
            }
        });
        
        try {
            parent.postMessage({ action: 'console', level, args: stringifiedArgs }, '*');
        } catch (err) {
            parent.postMessage({ action: 'console', level: 'unclonable' }, '*');
        }

        original(...args);
    };
});


const app = new App({ target: document.body });

export default app;
